<template>
  <div
    class="pb-largeimageoverlap"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  >
    <div class="pb-largeimageoverlap-color-container">
      <h2>{{ schema.Title }}</h2>
      <div class="pb-largeimageoverlap-color-divider"></div>
      <p>{{ schema.Description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "pblargeimageoverlapoverlap",
  props: {
    schema: {
      type: Object
    }
  },
  data() {
    return {
      image: ""
    };
  },
  mounted() {
    this.image =
      process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
      this.schema.Cover.data.attributes.url;
  }
};
</script>

<style>
.pb-largeimageoverlap {
  height: 70vh;

  background-position: center;
  background-size: cover;

  margin: -10vh 0 10vh 0;

  width: 100vw;
  overflow-x: hidden;
}

.pb-largeimageoverlap-color-container {
  width: 40vw;
  height: 70vh;

  position: absolute;
  top: 0;
  right: -20vw;

  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 0.9) 0%,
    rgba(28, 133, 164, 0.9) 100%
  );

  box-shadow: 0 0 10vh 0 rgba(0, 0, 0, 0.25);

  border-bottom-left-radius: 60vh;
  border-bottom-right-radius: 60vh;

  margin: 0 0 0 0;
  padding: 0 20vw 0 10vw;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}

.pb-largeimageoverlap-color-container h2 {
  margin: 0;
  padding: 0;

  color: white;
}

.pb-largeimageoverlap-color-container p {
  margin: 0;
  padding: 0;

  color: white;

  padding-right: 15vw;

  text-align: left;
}

.pb-largeimageoverlap-color-divider {
  height: 1px;
  width: 5vw;
  background-color: white;

  margin: 1vh 0 1vh 0;
}

@media only screen and (max-width: 600px) {
  .pb-largeimageoverlap-color-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 80vw;
    height: 60vh;

    padding: 0 0 10vh 20vw;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 40vh;
  }
  .pb-largeimageoverlap {
    height: 100vh;
  }
}
</style>
