<template>
  <div class="header-container">
    <div class="header-flex-container">
      <div class="header-flex">
        <a href="home"><img :src="siteLogo" class="header-logo"/></a>
      </div>
      <div class="header-flex">
        <ul class="header-navigation">
          <li v-for="menuItem in menuArray" :key="menuItem">
            <a
              v-if="menuItem.Page.data != null"
              :href="menuItem.Page.data.attributes.PageUID"
            >
              {{ menuItem.Name }}
              <img
                src="/assets/icons/arrow_down.svg"
                class="header-navigation-chevron"
                v-if="menuItem.NavigationItem.length != 0"
              />
              <div
                class="header-navigation-dropdown"
                v-if="menuItem.NavigationItem.length != 0"
              >
                <ul>
                  <div v-for="ddItem in menuItem.NavigationItem" :key="ddItem">
                    <a
                      v-if="ddItem.Page.data != null"
                      :href="ddItem.Page.data.attributes.PageUID"
                    >
                      <li>
                        {{ ddItem.Name }}
                      </li>
                    </a>
                  </div>
                </ul>
              </div>
            </a>
            <a v-if="menuItem.Page.data == null">
              {{ menuItem.Name }}
              <img
                src="/assets/icons/arrow_down.svg"
                class="header-navigation-chevron"
                v-if="menuItem.NavigationItem.length != 0"
              />
              <div
                class="header-navigation-dropdown"
                v-if="menuItem.NavigationItem.length != 0"
              >
                <ul>
                  <div v-for="ddItem in menuItem.NavigationItem" :key="ddItem">
                    <a
                      v-if="ddItem.Page.data != null"
                      :href="ddItem.Page.data.attributes.PageUID"
                    >
                      <li>
                        {{ ddItem.Name }}
                      </li>
                    </a>
                  </div>
                </ul>
              </div>
            </a>
          </li>
        </ul>

        <img
          class="header-navigation-icon"
          src="/assets/icons/menu.svg"
          v-if="drawer == false"
          @click="drawer = true"
        />

        <img
          class="header-navigation-icon"
          src="/assets/icons/close.svg"
          v-if="drawer == true"
          @click="drawer = false"
        />
      </div>
    </div>
  </div>
  <div class="drawer" :class="{ open: drawer }">
    <div class="header-navigation-wrapper">
      <div
        class="footer-navigation-item"
        v-for="menuItem in menuArray"
        :key="menuItem"
      >
        <a
          v-if="menuItem.Page.data != null"
          :href="menuItem.Page.data.attributes.PageUID"
        >
          <h3>{{ menuItem.Name }}</h3>
        </a>
        <h3 v-if="menuItem.Page.data == null">{{ menuItem.Name }}</h3>
        <div class="footer-navigation-divider"></div>
        <ul v-if="menuItem.NavigationItem.length != 0">
          <li v-for="ddItem in menuItem.NavigationItem" :key="ddItem">
            <a
              v-if="ddItem.Page.data != null"
              :href="ddItem.Page.data.attributes.PageUID"
            >
              <li>
                {{ ddItem.Name }}
              </li>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      menuArray: [],
      siteLogo: null,
      drawer: false
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup: function() {
      let query = "api/navigation?populate=deep";

      axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then(response => {
        let data = response.data.data;
        if (data.length != 0) {
          this.menuArray = data.attributes.NavigationDropdown;
        } else {
          console.log("Header problem");
        }
      });

      let siteQuery = "api/site?populate=deep";

      axios.get(process.env.VUE_APP_CMS_DOMAIN + siteQuery).then(response => {
        let data = response.data.data;
        if (data.length != 0) {
          this.siteLogo =
            process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
            data.attributes.LogoColor.data.attributes.url;
        } else {
          console.log("Header problem");
        }
      });
    }
  }
};
</script>

<style>
.header-container {
  width: 100vw;
  height: 10vh;

  background-color: rgba(255, 255, 255, 0.85);

  display: flex;
  justify-content: center;

  position: relative;
  z-index: 100;
}

.header-flex-container {
  width: 80vw;
  height: 100%;

  display: flex;
}

.header-flex {
  flex: 1;
  height: 100%;

  display: flex;

  align-items: center;

  position: relative;
}

.header-flex:nth-child(2) {
  flex: 1;
  justify-content: flex-end;
}

.header-navigation {
  margin: 0;
  padding: 0;
  display: flex;

  width: 100%;

  justify-content: space-between;
}

.header-navigation li {
  flex-grow: 1;
  list-style-type: none;

  height: 10vh;
  position: relative;
  z-index: 200;

  display: flex;

  align-items: center;

  padding: 0 0 0 1vw;
}
.header-navigation li a {
  color: initial;
  text-decoration: none;

  color: #276c8d;

  font-weight: 300;
  font-size: 12pt;
}
.header-navigation-chevron {
  width: 1vw;
  height: 1vw;
  padding-left: 0.5vw;
}

.header-navigation li:hover {
  cursor: pointer;
}

.header-navigation li .header-navigation-dropdown {
  top: -20vh;
  opacity: 0;
}

.header-navigation li:hover .header-navigation-dropdown {
  top: 10vh;
  opacity: 1;
}

.header-navigation-dropdown {
  position: absolute;
  left: 0;

  transition: all 250ms;

  z-index: 50;
}

.header-navigation-dropdown ul {
  margin: 0;
  padding: 0;

  background-color: white;
  box-shadow: 0 0 1vw 0 rgba(0, 0, 0, 0.05);
}

.header-navigation-dropdown a {
  text-decoration: none;
}

.header-navigation-dropdown li {
  height: 6vh;
  white-space: nowrap;

  padding: 0 1vw 0 1vw;
}

.header-navigation-dropdown li:hover {
  height: 6vh;
  white-space: nowrap;

  padding: 0 1vw 0 1vw;

  background-color: #276c8d;
  color: white;
}

.header-logo {
  height: 6vh;
}

.header-navigation-icon {
  display: none;
}

.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -75vw;
  width: 75vw;

  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 100%
  );

  z-index: 200;
  box-shadow: none;

  transition: all 500ms;
}

@media only screen and (max-width: 600px) {
  .header-navigation {
    display: none;
  }

  .header-navigation-icon {
    display: block;
    height: 6vh;
    cursor: pointer;
  }

  .open {
    left: 0vw;
    box-shadow: 0 0 20vh 0 rgba(0, 0, 0, 0.2);
  }

  .header-navigation-wrapper {
    height: 70vh;
    overflow-y: auto;
  }

  .header-navigation-wrapper {
    padding: 10vh 5vw 10vh 5vw;
  }

  .header-navigation-wrapper .footer-navigation-item h3 {
    color: white;
  }

  .header-navigation-wrapper .footer-navigation-item ul li {
    color: white;
  }

  .header-navigation-wrapper .footer-navigation-divider {
    background-color: white;
  }
}
</style>
