<template>
  <div class="imagify-pagebuilder">
    <div v-for="(schema, index) in componentList" :key="index">
      <component :key="index" :is="schema.type" :schema="schema"></component>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import pblargeimage from "@/pagebuilder/pb-largeimage.vue";
import pblargeimageoverlap from "@/pagebuilder/pb-largeimageoverlap.vue";
import pbpagetextimage from "@/pagebuilder/pb-pagetextimage.vue";
import pbpagetext from "@/pagebuilder/pb-pagetext.vue";
import pbsitetitle from "@/pagebuilder/pb-sitetitle.vue";
import pbteam from "@/pagebuilder/pb-team.vue";
import pbpartners from "@/pagebuilder/pb-partners.vue";
import pbpeopledisplay from "@/pagebuilder/pb-peopledisplay.vue";
import pbmethodsdisplay from "@/pagebuilder/pb-methodsdisplay.vue";
import pblargeimagealt from "@/pagebuilder/pb-largeimagealt.vue";
import pbcontactform from "@/pagebuilder/pb-contactform.vue";

export default {
  data() {
    return {
      pageName: null,
      setupComplete: false,
      componentList: []
    };
  },
  components: {
    pblargeimage,
    pblargeimageoverlap,
    pbpagetextimage,
    pbpagetext,
    pbsitetitle,
    pbteam,
    pbpartners,
    pbpeopledisplay,
    pbmethodsdisplay,
    pblargeimagealt,
    pbcontactform
  },
  methods: {
    setup: function() {
      // Get Page Name
      if (!this.setupComplete) {
        this.pageName = this.$route.params.page;

        let query =
          "api/pages?filters[PageUID]=" + this.pageName + "&populate=deep";

        axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then(response => {
          let data = response.data.data;
          if (data.length == 0) {
            // Page not found
            let query = "api/pages?filters[PageUID]=404&populate=deep";
            axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then(response => {
              let data = response.data.data;
              if (data.length == 0) {
                console.log("404 Page not found");
              } else {
                this.buildPage(data);
              }
            });
          } else {
            this.buildPage(data);
          }
        });

        this.setupComplete = true;
      }
    },
    buildPage: function(data) {
      let components = data[0].attributes.Components;
      for (var i = 0; i < components.length; i++) {
        components[i].type =
          "pb" +
          components[i].__component
            .split("components.")
            .pop()
            .replace("-", "")
            .replace("-", "");
        this.componentList.push(components[[i]]);
      }
    }
  },
  mounted() {
    this.setup();
  },
  updated() {
    this.setup();
  }
};
</script>

<style>
.imagify-pagebuilder {
  position: relative;
  width: 100vw;
  overflow-x: hidden;
}
</style>
