<template>
  <div class="pb-methodsdisplay">
    <div class="pb-methodsdisplay-flex left">
      <h2 class="pb-methodsdisplay-title">{{ schema.title }}</h2>
      <p class="pb-methodsdisplay-description">{{ schema.description }}</p>
    </div>
    <div
      class="pb-methodsdisplay-flex"
      v-for="method in schema.methods"
      :key="method"
      :style="{
        backgroundImage:
          'url(' + cmsURL + method.image.data.attributes.url + ')'
      }"
    >
      <div class="pb-methodsdisplay-inner">
        <h2>{{ method.name }}</h2>
        <h3>{{ method.description }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MethodsDisplay",
  props: {
    schema: {
      type: Object
    }
  },
  data() {
    return {
      cmsURL: ""
    };
  },
  mounted() {
    this.cmsURL = process.env.VUE_APP_CMS_DOMAIN.slice(0, -1);
  }
};
</script>

<style>
.pb-methodsdisplay {
  width: 80vw;
  margin: 0 0 10vh 10vw;
  display: flex;
}

.pb-methodsdisplay-flex {
  flex: 1;

  width: 100%;
  height: 35vh;

  margin: 0 0.5vw 0 0.5vw;

  position: relative;

  border-radius: 2vh;

  background-position: center;
  background-size: cover;
}

.pb-methodsdisplay-flex:first-child {
  margin: 0 0.5vw 0 0;
}

.pb-methodsdisplay-flex:last-child {
  margin: 0 0 0 0.5vw;
}

.pb-methodsdisplay-inner {
  width: 100%;
  height: 13vh;

  background: rgb(51, 82, 117);
  background: linear-gradient(
    90deg,
    rgba(51, 82, 117, 0.9) 0%,
    rgba(28, 133, 164, 0.9) 100%
  );

  background-size: 250%;
  background-position: 0;

  border-radius: 2vh;

  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  transition: all 250ms;
}

.pb-methodsdisplay-inner h2 {
  margin: 0;

  padding: 2vh 2vw 0vh 2vw;

  color: white;
  text-align: left;
}

.pb-methodsdisplay-inner h3 {
  margin: 0;

  padding: 0vh 2vw 2vh 2vw;

  color: white;
  text-align: left;
}

.pb-methodsdisplay-title {
  font-size: 16pt;

  margin: 0 0 1.5vh 0;
  padding: 2vh 0 0.5vh 0;

  position: relative;

  color: #335275;
}

.pb-methodsdisplay-title::after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 50%;
  height: 2px;

  background-color: #335275;

  content: "";
}

.pb-methodsdisplay-flex.left {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.pb-methodsdisplay-description {
  text-align: left;
  padding-right: 2vw;
}

@media only screen and (max-width: 600px) {
  .pb-methodsdisplay {
    display: block;
  }

  .pb-methodsdisplay-flex {
    margin-bottom: 4vh;
  }
}
</style>
