<template>
  <div class="footer-container">
    <div class="footer-flex-container">
      <div class="footer-flex">
        <img :src="siteLogoWhite" class="footer-sitelogo" />
      </div>
      <div class="footer-flex">
        <div class="footer-navigation-wrapper">
          <div
            class="footer-navigation-item"
            v-for="menuItem in menuArray"
            :key="menuItem"
          >
            <a
              v-if="menuItem.Page.data != null"
              :href="menuItem.Page.data.attributes.PageUID"
            >
              <h3>{{ menuItem.Name }}</h3>
            </a>
            <h3 v-if="menuItem.Page.data == null">{{ menuItem.Name }}</h3>
            <div class="footer-navigation-divider"></div>
            <ul v-if="menuItem.NavigationItem.length != 0">
              <li v-for="ddItem in menuItem.NavigationItem" :key="ddItem">
                <a
                  v-if="ddItem.Page.data != null"
                  :href="ddItem.Page.data.attributes.PageUID"
                >
                  <li>
                    {{ ddItem.Name }}
                  </li>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      menuArray: [],
      siteLogoWhite: null,
      siteCopyright: ""
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup: function() {
      let query = "api/navigation?populate=deep";

      axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then(response => {
        let data = response.data.data;
        if (data.length != 0) {
          this.menuArray = data.attributes.NavigationDropdown;
        } else {
          console.log("Footer problem");
        }
      });

      let siteQuery = "api/site?populate=deep";

      axios.get(process.env.VUE_APP_CMS_DOMAIN + siteQuery).then(response => {
        let data = response.data.data;
        if (data.length != 0) {
          this.siteLogoWhite =
            process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
            data.attributes.LogoWhite.data.attributes.url;

          this.siteCopyright = data.attributes.Copyright;
        } else {
          console.log("Footer problem");
        }
      });
    }
  }
};
</script>

<style>
.footer-container {
  width: 100vw;
  height: 40vh;

  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 100%
  );

  display: flex;
  justify-content: center;

  position: relative;
  z-index: 50;
}

.footer-flex-container {
  width: 80vw;
  height: 100%;

  display: flex;
}
.footer-flex:nth-child(1) {
  width: 15vw;

  display: flex;
}

.footer-flex:nth-child(2) {
  flex-grow: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-navigation-wrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.footer-sitelogo {
  height: 6vh;
  margin: 5vh 0 0 0;
}

.footer-navigation-item {
  flex-grow: 1;
}

.footer-navigation-item li a {
  text-decoration: none;
}

.footer-navigation-item li a:hover {
  text-decoration: underline;
  color: white;
  cursor: pointer;
}

.footer-navigation-item h3 {
  color: white;

  margin: 0;
  padding: 0;

  text-align: left;
}

.footer-navigation-item a {
  text-decoration: none;
}

.footer-navigation-item a h3:hover {
  color: white;

  text-decoration: underline;
}

.footer-navigation-item:last-child h3 {
  /*text-align: right;*/
}

.footer-navigation-divider {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 1vh 0 1vh 0;
}

.footer-navigation-item ul {
  margin: 0;
  padding: 0;
}

.footer-navigation-item ul li {
  display: flex;
  list-style-type: none;
  padding: 0.35vh 0 0.35vh 0;

  color: white;
}
@media only screen and (max-width: 600px) {
  .footer-container {
    height: fit-content;
  }
  .footer-navigation-wrapper {
    flex-direction: column;
  }

  .footer-flex-container {
    flex-direction: column;
  }

  .footer-flex {
    padding: 4vh 0 2vh 0;
  }
  .footer-sitelogo {
    height: 10vh;
  }

  .footer-navigation-item:last-child h3 {
    text-align: left;
  }

  .footer-navigation-item {
    padding: 1vh 0 1vh 0;
  }
}
</style>
