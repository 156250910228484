<template>
  <div class="main-container">
    <Header></Header>

    <router-view />

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      drawerOpen: false
    };
  }
};
</script>

<style>
@import "../node_modules/typeface-roboto/index.css";

html,
body {
  margin: 0;
  height: 100%;

  background-color: white;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  text-align: center;
  color: #2c3e50;

  height: 100%;
}
</style>
