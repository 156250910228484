<template>
  <div class="pb-pagetextimage">
    <div class="pb-pagetextimage-flex-container">
      <div class="pb-pagetextimage-flex">
        <div class="pb-pagetextimage-sitetitle" v-if="schema.Title != null">
          <h2 class="pb-pagetextimage-sitetitle-title">{{ schema.Title }}</h2>
        </div>
        <vue-markdown :source="schema.Text" />
      </div>
      <div
        class="pb-pagetextimage-flex"
        :style="{ backgroundImage: 'url(' + image + ')' }"
      ></div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";

export default {
  name: "pbpagetextimage",
  props: {
    schema: {
      type: Object
    }
  },
  components: {
    VueMarkdown
  },
  data() {
    return {
      image: ""
    };
  },
  mounted() {
    this.image =
      process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
      this.schema.Image.data.attributes.url;
  }
};
</script>

<style>
.pb-pagetextimage {
  display: flex;
  justify-content: center;
}

.pb-pagetextimage-flex-container {
  padding: 10vh 0 10vh 0;
  width: 80vw;

  display: flex;
}

.pb-pagetextimage-flex {
  flex: 1;

  display: flex;

  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
  text-align: left;

  background-position: center;
  background-size: cover;

  min-height: 50vh;

  border-radius: 2vh;
}

.pb-pagetextimage-flex p {
  margin: 0;
  padding: 1vh 0 1vh 0;

  width: 70%;
}

.pb-pagetextimage-sitetitle {
  display: flex;
}

.pb-pagetextimage-sitetitle-title {
  font-size: 16pt;

  margin: 0 0 1.5vh 0;
  padding: 2vh 0 0.5vh 0;

  position: relative;

  color: #335275;
}

.pb-pagetextimage-sitetitle-title::after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 50%;
  height: 2px;

  background-color: #335275;

  content: "";
}

@media only screen and (max-width: 600px) {
  .pb-pagetextimage-flex-container {
    flex-direction: column;
    padding: 5vh 0 5vh 0;
  }

  .pb-pagetextimage-flex p {
    width: 100%;
    padding-bottom: 2vh;
  }

  .pb-pagetextimage-flex {
    padding-bottom: 5vh;
  }
}
</style>
