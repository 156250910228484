<template>
  <div class="pb-largeimage" :style="{ backgroundImage: 'url(' + image + ')' }">
    <div class="pb-largeimage-color-container">
      <h2>{{ schema.Title }}</h2>
      <div class="pb-largeimage-color-divider"></div>
      <p>{{ schema.Description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "pblargeimage",
  props: {
    schema: {
      type: Object
    }
  },
  data() {
    return {
      image: ""
    };
  },
  mounted() {
    this.image =
      process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
      this.schema.Cover.data.attributes.url;
  }
};
</script>

<style>
.pb-largeimage {
  height: 70vh;

  background-position: center;
  background-size: cover;
}

.pb-largeimage-color-container {
  width: 30vw;
  height: 60vh;

  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 100%
  );

  border-bottom-right-radius: 60vh;

  margin: -10vh 0 0 0;
  padding: 0 0 0 10vw;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}

.pb-largeimage-color-container h2 {
  margin: 0;
  padding: 0 15vw 0 0;

  text-align: left;

  color: white;
}

.pb-largeimage-color-container p {
  margin: 0;
  padding: 0;

  color: white;

  padding-right: 15vw;

  text-align: left;
}

.pb-largeimage-color-divider {
  height: 1px;
  width: 5vw;
  background-color: white;

  margin: 1vh 0 1vh 0;
}

@media only screen and (max-width: 600px) {
  .pb-largeimage {
    height: 100vh;
  }

  .pb-largeimage-color-container {
    width: 90vw;
    height: 80vh;

    padding-right: 10vw;
  }
}
</style>
