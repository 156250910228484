<template>
  <div class="pb-pagetext">
    <div class="pb-pagetext-flex-container">
      <div class="pb-pagetext-flex">
        <vue-markdown :source="schema.Text" />
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";

export default {
  name: "pbpagetext",
  props: {
    schema: {
      type: Object
    }
  },
  components: {
    VueMarkdown
  }
};
</script>

<style>
.pb-pagetext {
  display: flex;
  justify-content: center;

  padding-bottom: 10vh;
}

.pb-pagetext-flex-container {
  padding: 0;
  width: 80vw;

  display: flex;
}
.pb-pagetext-flex {
  flex: 1;

  display: flex;

  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
  text-align: left;

  background-position: center;
  background-size: cover;

  border-radius: 2vh;
}
.pb-pagetext-flex p {
  margin: 0;
  padding: 1vh 0 1vh 0;

  width: 70%;
}
</style>
