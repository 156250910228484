<template>
  <div class="pb-contactform">
    <div class="pb-contactform-container">
      <div class="pb-contactform-flex">
        <label class="pb-contactform-label">{{ schema.Firstname }}</label>
        <input type="text" class="pb-contactform-input" id="formfirstname" />
        <label class="pb-contactform-label">{{ schema.Email }}</label>
        <input type="text" class="pb-contactform-input" id="formemail" />
      </div>
      <div class="pb-contactform-flex">
        <label class="pb-contactform-label">{{ schema.Lastname }}</label>
        <input type="text" class="pb-contactform-input" id="formlastname" />
        <label class="pb-contactform-label">{{ schema.Phone }}</label>
        <input type="text" class="pb-contactform-input" id="formphone" />
        <label class="pb-contactform-label">{{ schema.Message }}</label>
        <textarea class="pb-contactform-input ta" id="formmessage"></textarea>
        <input
          type="button"
          class="pb-contactform-input-button"
          :value="schema.Send"
          @click="sendmail()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "pbcontactform",
  props: {
    schema: {
      type: Object
    }
  },
  methods: {
    sendmail() {
      let firstname = document.getElementById("formfirstname");
      let lastname = document.getElementById("formlastname");
      let email = document.getElementById("formemail");
      let phone = document.getElementById("formphone");
      let message = document.getElementById("formmessage");

      firstname.classList.remove("error");
      lastname.classList.remove("error");
      email.classList.remove("error");
      phone.classList.remove("error");
      message.classList.remove("error");

      let allgood = true;

      if (firstname.value == "") {
        firstname.classList.add("error");
        allgood = false;
      }

      if (lastname.value == "") {
        lastname.classList.add("error");
        allgood = false;
      }

      if (email.value == "") {
        email.classList.add("error");
        allgood = false;
      }
      if (phone.value == "") {
        phone.classList.add("error");
        allgood = false;
      }
      if (message.value == "") {
        message.classList.add("error");
        allgood = false;
      }

      if (allgood == true) {
        axios
          .post(process.env.VUE_APP_EMAILSENDER_DOMAIN, {
            headers: {
              "Content-Type": "application/json"
            },
            data: {
              name: "firstname",
              email: "ralph.hermes@imagify.lu",
              subject: "SLDV Website contact form",
              message: "message"
            }
          })
          .then(response => {
            let data = response;
            console.log(data);
            if (data != 1) {
              firstname.classList.remove("error");
              lastname.classList.remove("error");
              email.classList.remove("error");
              phone.classList.remove("error");
              message.classList.remove("error");

              firstname.classList.add("success");
              lastname.classList.add("success");
              email.classList.add("success");
              phone.classList.add("success");
              message.classList.add("success");
            }
          });
      }
    }
  }
};
</script>

<style>
.pb-contactform-container {
  width: 80vw;
  margin: 20vh 10vw 10vh 10vw;

  display: flex;
}

.pb-contactform-input {
  height: 4vh;
  margin: 1vh 0 0 0;
  width: calc(100% - 4vh);

  padding: 1vh;
  border: none;

  background: rgb(238, 238, 238);
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(250, 250, 250, 1) 100%
  );

  border: 1px solid #eee;
  border-radius: 1vh;
}

.pb-contactform-input.ta {
  min-height: 15vh;
  margin: 1vh 0 0 0;
  width: calc(100% - 4vh);

  padding: 1vh;
  border: none;

  background: rgb(238, 238, 238);
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(250, 250, 250, 1) 100%
  );

  border: 1px solid #eee;
  border-radius: 1vh;
}

.pb-contactform-input.error {
  border: 1px solid rgb(193, 30, 30);
}

.pb-contactform-input.success {
  border: 1px solid rgb(29, 181, 37);
}

.pb-contactform-input-button {
  height: 6vh;
  margin: 4vh 1vw 0 0;
  width: 50%;

  padding: 1vh;
  border: none;

  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 50%,
    #eee 50%,
    #f9f9f9 100%
  );

  background-size: 250%;
  background-position: 100%;

  align-self: flex-end;

  border: 1px solid #eee;
  border-radius: 1vh;

  color: #444;

  transition: all 250ms;
}

.pb-contactform-input-button:hover {
  background-position: 0;
  cursor: pointer;
  color: white;
  box-shadow: 0 0 2vh 0 rgba(0, 0, 0, 0.25);
}

.pb-contactform-flex {
  flex: 1;

  display: flex;
  flex-direction: column;

  align-content: flex-start;
  align-items: flex-start;
}

.pb-contactform-label {
  margin: 2vh 0 0 0;
}

@media only screen and (max-width: 600px) {
  .pb-contactform-container {
    flex-direction: column;
  }
}
</style>
