<template>
  <div class="pb-sitetitle">
    <h2 class="pb-sitetitle-title">{{ schema.Title }}</h2>
  </div>
</template>

<script>
export default {
  name: "pbsitetitle",
  props: {
    schema: {
      type: Object
    }
  }
};
</script>

<style>
.pb-sitetitle {
  display: flex;

  width: 80vw;
  margin: 0 0 0 10vw;
}

.pb-sitetitle-title {
  font-size: 16pt;

  margin: 0 0 1.5vh 0;
  padding: 2vh 0 0.5vh 0;

  position: relative;

  color: #335275;
}

.pb-sitetitle-title::after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 50%;
  height: 2px;

  background-color: #335275;

  content: "";
}
</style>
