<template>
  <div class="pb-largeimagealt">
    <div class="pb-largeimagealt-container">
      <div class="pb-largeimagealt-flex">
        <h2 class="pb-largeimagealt-title">{{ schema.Title }}</h2>
        <p class="pb-largeimagealt-description">{{ schema.description }}</p>
      </div>
      <div class="pb-largeimagealt-flex">
        <div
          class="pb-largeimagealt-image"
          :style="{ backgroundImage: 'url(' + image + ')' }"
        >
          asd
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pblargeimagealt",
  props: {
    schema: {
      type: Object
    }
  },
  data() {
    return {
      image: ""
    };
  },
  mounted() {
    this.image =
      process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
      this.schema.Image.data.attributes.url;
  }
};
</script>

<style>
.pb-largeimagealt {
  height: 100vh;
}
.pb-largeimagealt-container {
  width: 90vw;
  display: flex;
  margin: 0 0 0 10vw;
}

.pb-largeimagealt-flex {
  flex: 1;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.pb-largeimagealt-title {
  font-size: 16pt;

  margin: 10vh 0 1.5vh 0;
  padding: 2vh 0 0.5vh 0;

  position: relative;

  color: #335275;
}

.pb-largeimagealt-title::after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 50%;
  height: 2px;

  background-color: #335275;

  content: "";
}

.pb-largeimagealt-description {
  text-align: left;

  margin: 0 0 10vh 0;
  width: 75%;
}

.pb-largeimagealt-image {
  position: absolute;
  width: 80vw;
  height: 80vw;

  border-bottom-left-radius: 40vw;
  border-bottom-right-radius: 40vw;

  margin-top: -40vw;

  background-position: center;
  background-size: cover;
}
</style>
