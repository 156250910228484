<template>
  <div class="pb-partners-container">
    <div class="pb-partners-flex" v-for="partner in partnerList" :key="partner">
      <a :href="partner.link" target="_blank"
        ><img :src="partner.image" class="pb-partners-image"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "pbpartners",
  props: {
    schema: {
      type: Object
    }
  },
  data() {
    return {
      partnerList: []
    };
  },
  mounted() {
    for (var i = 0; i < this.schema.Partner.length; i++) {
      let partner = this.schema.Partner[i];

      let image =
        process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
        partner.Image.data.attributes.url;

      let partnerObject = {
        image: image,
        link: partner.Link
      };

      this.partnerList.push(partnerObject);
    }
  }
};
</script>

<style>
.pb-partners-container {
  display: flex;

  width: 80vw;
  margin: 5vh 0 10vh 10vw;
  flex-wrap: wrap;
}

.pb-partners-flex {
  flex: 0 0 20%;
  padding: 4vh 0 4vh 0;
}

.pb-partners-flex img {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .pb-partners-flex {
    flex-basis: 40%;
    flex-grow: 1;
  }
}
</style>
