import { createRouter, createWebHistory } from "vue-router";
import PageBuilder from "../views/PageBuilder.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/page/:page", component: PageBuilder },
    { path: "/", redirect: "/page/home" }
  ]
});

export default router;
