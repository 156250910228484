<template>
  <div class="pb-team-item">
    <div class="pb-team-inner-container">
      <div class="pb-team-inner-flex">
        <div class="pb-team-profile" v-if="doctorProfile == null">
          <img
            src="/assets/icons/profile.svg"
            class="pb-team-profile-placeholder"
          />
        </div>
        <div
          class="pb-team-profile"
          v-if="doctorProfile != null"
          v-bind:style="{
            backgroundImage: 'url(' + doctorProfile + ')'
          }"
        ></div>
      </div>
      <div class="pb-team-inner-flex">
        <h3>
          {{ doctorInfo.title }} {{ doctorInfo.firstname }}
          {{ doctorInfo.lastname }}
        </h3>
        <h4>{{ doctorInfo.city }}</h4>
        <!--
        <ul class="pb-team-specialty-list">
          <li>List Item 1</li>
          <li>List Item 1</li>
        </ul>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cmpt-teamitem",
  props: {
    doctorInfo: Object
  },
  data() {
    return {
      doctorProfile: ""
    };
  },
  mounted() {
    if (this.doctorInfo.profile.data) {
      this.doctorProfile =
        process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
        this.doctorInfo.profile.data.attributes.url;
    } else {
      this.doctorProfile = null;
    }
  }
};
</script>

<style>
.pb-team-item {
  flex: 0 0 25.5vw;

  min-height: 5vh;

  background: #f9f9f9;
  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 50%,
    #f9f9f9 50%,
    #f9f9f9 100%
  );

  background-size: 250%;
  background-position: 100%;
  margin: 0.5vw 0 0.5vw 0;
  padding: 2vh 0 2vh 0;

  border-radius: 1vh;

  transition: all 750ms;
  transition-timing-function: ease;
}

.pb-team-item:hover {
  box-shadow: 0 0 2vh 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  background-position: 0;
  color: white;
  transition: all 350ms;
  transition-timing-function: ease;
}

.pb-team-item:hover h3 {
  color: white;
  transition: all 350ms;
}

.pb-team-item:hover h4 {
  color: white;
  transition: all 350ms;
}

.pb-team-item:nth-child(1),
.pb-team-item:nth-child(3n + 1) {
  margin: 0.5vw 1vw 0.5vw 0;
}

.pb-team-item:nth-child(3n + 3) {
  margin: 0.5vw 0 0.5vw 1vw;
}

.pb-team-inner-container {
  display: flex;
  height: 100%;
}

.pb-team-inner-flex {
  display: flex;
  align-items: center;

  flex: 1;
}

.pb-team-inner-flex:first-child {
  justify-content: center;
  flex-basis: 33.33%;
}

.pb-team-inner-flex:last-child {
  flex-basis: 66.66%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}

.pb-team-profile {
  height: 10vh;
  width: 10vh;

  background-color: #ccc;
  background-position: center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5vh;
}

.pb-team-profile img {
  width: 4vh;
  height: 4vh;
}

.pb-team-inner-flex h3,
.pb-team-inner-flex h4 {
  margin: 0;
  padding: 0;
  transition: all 750ms;
}

.pb-team-inner-flex h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16pt;

  color: #818384;
}

.pb-team-inner-flex h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12pt;

  color: #818384;
}

.pb-team-specialty-list {
  margin: 1vh 0 0 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
}

.pb-team-specialty-list li {
  list-style-type: none;

  padding: 0.5vh 1vh 0.5vh 1vh;
  border: 1px solid #818384;
  border-radius: 4vh;
  margin: 0 0.5vw 0 0;
  color: #818384;

  font-size: 11pt;

  transition: 500ms;
}

.pb-team-item:hover .pb-team-specialty-list li {
  border: 1px solid white;
  color: white;
}
@media only screen and (max-width: 600px) {
  .pb-team-inner-flex h3 {
    text-align: left;

    font-size: 12pt;
  }
}
</style>
