<template>
  <div class="pb-team">
    <div class="pb-team-searchbox">
      <input
        class="pb-team-searchbar"
        v-model="search"
        placeholder="Recherche"
      />
    </div>
    <div class="pb-team-relative">
      <Transition>
        <div class="pb-team-container" v-if="teamItemsVisible">
          <teamitem
            v-for="doctorInfo in filteredList"
            :key="doctorInfo"
            :doctorInfo="doctorInfo"
            @click="selectTeamItem(doctorInfo)"
          ></teamitem>
        </div>
      </Transition>

      <Transition>
        <div class="pb-team-pageination-container" v-if="teamItemsVisible">
          <ul class="pb-team-pageination">
            <li
              v-for="pgntn in listMaxPages"
              :key="pgntn"
              @click="listSelectPage(pgntn)"
              :class="{ active: pgntn == listCurrentPage + 1 }"
            >
              {{ pgntn }}
            </li>
          </ul>
        </div>
      </Transition>

      <Transition>
        <div class="pb-team-details-container" v-if="!teamItemsVisible">
          <div class="pb-team-details-flex name">
            <div class="pb-team-profile details" v-if="doctorProfile == null">
              <img
                src="/assets/icons/profile.svg"
                class="pb-team-profile-placeholder"
              />
            </div>
            <div
              class="pb-team-profile details"
              v-if="doctorProfile != null"
              v-bind:style="{
                backgroundImage: 'url(' + doctorProfile + ')'
              }"
            ></div>
            <h2 class="pb-team-details-name">
              {{ currentDoctorInfos.title }} {{ currentDoctorInfos.firstname }}
              {{ currentDoctorInfos.lastname }}
            </h2>
            <h3 class="pb-team-details-name">
              {{ currentDoctorInfos.city }}
            </h3>
          </div>
          <div class="pb-team-details-flex details">
            <ul class="pb-team-details-list">
              <li>
                {{ currentDoctorInfos.number }}, {{ currentDoctorInfos.street
                }}<br />{{ currentDoctorInfos.country.charAt(0) }}-{{
                  currentDoctorInfos.zipcode
                }}
                {{ currentDoctorInfos.city }}<br />
                {{ currentDoctorInfos.country }}
              </li>
              <li>{{ currentDoctorInfos.phone }}</li>
            </ul>
          </div>
          <div class="pb-team-details-flex close">
            <label
              class="pb-team-details-close"
              @click="teamItemsVisible = true"
              >Fermer X</label
            >
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import teamitem from "@/pagebuilder/components/component-team-item.vue";

export default {
  name: "pbteam",
  props: {
    schema: {
      type: Object
    }
  },
  components: {
    teamitem
  },
  data() {
    return {
      doctorInfos: [],
      search: "",
      listMaxItems: 6,
      listCurrentPage: 0,
      listMaxPages: 0,
      teamItemsVisible: true,
      currentDoctorInfos: Object,
      doctorProfile: ""
    };
  },
  mounted() {
    let query = "api/doctors?populate=deep&pagination[pageSize]=100";

    axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then(response => {
      let data = response.data.data;
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let dataItem = data[i];

          let doctorInfo = {
            title: dataItem.attributes.Title,
            firstname: dataItem.attributes.Firstname,
            lastname: dataItem.attributes.Lastname,
            number: dataItem.attributes.HouseNumber,
            street: dataItem.attributes.Street,
            addressplus: dataItem.attributes.AddressPlus,
            zipcode: dataItem.attributes.ZipCode,
            city: dataItem.attributes.City,
            country: dataItem.attributes.Country,
            phone: dataItem.attributes.Phone,
            profile: dataItem.attributes.Profile
          };

          this.doctorInfos.push(doctorInfo);
        }

        this.listMaxPages = Math.ceil(
          this.doctorInfos.length / this.listMaxItems
        );
      }
    });
  },
  computed: {
    filteredList() {
      var returnList = this.doctorInfos.filter(doctor => {
        let fullname =
          doctor.title.toLowerCase() +
          " " +
          doctor.firstname.toLowerCase() +
          " " +
          doctor.lastname.toLowerCase();
        let fullnamereverse =
          doctor.title.toLowerCase() +
          " " +
          doctor.lastname.toLowerCase() +
          " " +
          doctor.firstname.toLowerCase();

        return (
          fullname.toLowerCase().includes(this.search.toLowerCase()) ||
          fullnamereverse.toLowerCase().includes(this.search.toLowerCase())
        );
      });

      this.updateListSize(returnList.length);
      return returnList.slice(
        this.listCurrentPage * this.listMaxItems,
        this.listCurrentPage * this.listMaxItems + this.listMaxItems
      );
    }
  },
  methods: {
    listSelectPage: function(page) {
      this.listCurrentPage = page - 1;
    },
    updateListSize: function(arrayLength) {
      this.listMaxPages = Math.ceil(arrayLength / this.listMaxItems);
    },
    selectTeamItem: function(doctorInfo) {
      this.currentDoctorInfos = doctorInfo;
      this.teamItemsVisible = false;

      if (doctorInfo.profile.data) {
        this.doctorProfile =
          process.env.VUE_APP_CMS_DOMAIN.slice(0, -1) +
          doctorInfo.profile.data.attributes.url;
      } else {
        this.doctorProfile = null;
      }

      console.log(this.currentDoctorInfos);
    }
  },
  watch: {
    search: function() {
      this.teamItemsVisible = true;
      this.listCurrentPage = 0;
    }
  }
};
</script>

<style>
.pb-team {
  display: flex;

  width: 80vw;

  margin: 0 0 0 10vw;

  flex-direction: column;
}

.pb-team-container {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}

.pb-team-searchbar {
  height: 2vh;
  width: 15vw;

  border-radius: 1vh;
  border: 1pt solid #818384;

  padding: 1vh 2vh 1vh 2vh;

  font-size: 12pt;
}

.pb-team-pageination {
  display: flex;

  margin: 0;
  padding: 0;
}

.pb-team-pageination li {
  list-style-type: none;
  margin: 0 5pt 0 5pt;
  padding: 0.75em;
}

.pb-team-pageination li:hover {
  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 100%
  );
  color: white;
  border-radius: 5pt;

  cursor: pointer;
}

.pb-team-pageination li.active {
  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 100%
  );
  color: white;
  border-radius: 5pt;
}

.pb-team-pageination-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2vh 0 2vh 0;
}

.pb-team-searchbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 2vh 0 2vh 0;
}

.pb-team-details-container {
  display: flex;
  height: 100%;

  background-color: #f9f9f9;
  border-radius: 2vh;

  position: absolute;
  top: 7vh;
  left: 0;
  right: 0;
  bottom: 0;

  box-shadow: 0 0 2vh 0 rgba(0, 0, 0, 0.15);
  background: rgb(51, 82, 117);
  background: linear-gradient(
    120deg,
    rgba(51, 82, 117, 1) 0%,
    rgba(28, 133, 164, 1) 100%
  );
}

.pb-team-details-flex {
  flex: 1;
}

.pb-team-details-close {
  color: white;
  padding: 2vh 2vw 0 0;

  cursor: pointer;
}

.v-enter-from {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}

.v-enter-active {
  transition: opacity 0.5s ease;
}
.v-leave-active {
  transition: opacity 0.5s ease;
}

.pb-team-relative {
  position: relative;
  width: 100%;
  height: 25vh;
  margin-bottom: 10vh;
  margin-top: 0;
}

.pb-team-details-flex.details {
  display: flex;

  height: 100%;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;
}

.pb-team-details-list {
  text-align: left;

  font-size: 12pt;
  font-weight: 300;
}

.pb-team-details-list li {
  padding: 1vh 0 1vh 0;
  color: white;
}

.pb-team-profile.details {
  margin-left: 5vw;
  margin-top: -5vh;
}

.pb-team-details-flex.name {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.pb-team-details-flex h2 {
  margin: 2vh 0 0 2vw;
  padding: 0;

  font-size: 16pt;
  font-weight: 400;

  color: white;
}

.pb-team-details-flex h3 {
  margin: 0 0 0 2vw;
  padding: 0;

  font-size: 12pt;
  font-weight: 300;

  color: white;
}

.pb-team-details-flex.close {
  display: flex;

  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .pb-team-container {
    flex-direction: column;
  }

  .pb-team-relative {
    height: fit-content;
  }

  .pb-team-details-container {
    height: fit-content;
    position: relative;

    flex-direction: column;

    padding: 0 5vw 0 5vw;
  }

  .pb-team-details-flex.close {
    justify-content: center;
    padding: 2vh 0 4vh 0;
  }

  .pb-team-searchbar {
    height: 5vh;
    width: 72vw;
  }
}
</style>
